@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-card {
  width: fit-content;
  min-width: 20em;
}
.mat-form-field-infix {
  min-width: 40em;
}
